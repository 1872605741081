$(document).on("turbolinks:load", function() {
  $(".toggle-button").on("click", function() {
    $(".newsletter--header, .contact-page-header").hide();
    $(".navbar-sticky--pink-navbar").fadeIn();
  });

  if ($(".newsletter--header")[0] || $(".contact-page-header")[0]) {
    $(".navbar-sticky--pink-navbar--button-close").on("click", function() {
      $(".navbar-sticky--pink-navbar").fadeOut(function() {
        $(".newsletter--header, .contact-page-header").show();
      });
    });
  }

  const topHeaderPages = ['/free-app-landing-page'];
  if (topHeaderPages.includes(window.location.pathname)) {
    $(".navbar-container-open").fadeIn("slow");
  }
});
